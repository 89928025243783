import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { ClearIcon } from './icons';

import './styles/TextField.scss';

type Props = {
  label?: string;
  name?: string;
  value: string;
  onChange?: (inputValue: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  autoComplete?: boolean;
  errorMsg?: string;
  hasError?: boolean;
  icon?: JSX.Element;
  trailingIcon?: JSX.Element;
  showClear?: boolean;
  disabled?: boolean;
  size?: 'small' | 'large';
};

const ON_BLUR_TIMEOUT = 400;

const TextField: React.FC<Props> = ({
  label,
  name,
  value,
  onFocus,
  onChange,
  onBlur,
  placeholder,
  autoComplete,
  errorMsg,
  hasError,
  icon,
  trailingIcon,
  showClear,
  disabled,
  size = 'large',
}) => {
  const [hasFocus, setHasFocus] = useState(false);
  const [touched, setTouched] = useState(false);
  const blurTimeoutId = useRef(null);

  const showError = touched && (errorMsg || hasError);
  const showClearButton = showClear && value;

  useEffect(() => {
    return () => {
      if (blurTimeoutId?.current) clearTimeout(blurTimeoutId.current);
    };
  }, []);

  const setOnfocus = () => {
    setHasFocus(true);
    onFocus?.();
  };

  const setOnBlur = () => {
    setHasFocus(false);
    setTouched(true);
    blurTimeoutId.current = setTimeout(() => onBlur?.(), ON_BLUR_TIMEOUT);
  };

  return (
    <div
      className={cn(
        'textfield',
        { 'is--active': hasFocus || value },
        { 'has--focus': hasFocus },
        { 'has--icon': icon },
        { 'is--disabled': disabled },
        { 'no--label': !label },
        { 'no--onchange': !onChange },
        { 'has--trailingicon': trailingIcon || showClearButton },
        { 'has--error': showError },
        `size--${size}`,
      )}>
      {label && <label className="textfield-label">{label}</label>}
      <div className="textfield-input">
        {icon && <div className="textfield-icon">{icon}</div>}
        <input
          id={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={setOnBlur}
          onFocus={setOnfocus}
          autoComplete={autoComplete ? 'on' : 'off'}
          disabled={disabled}
          placeholder={placeholder ?? ''}
        />
        {showClearButton && (
          <div className="textfield-trailingicon has--cursor">
            <ClearIcon onClick={() => onChange('')}></ClearIcon>
          </div>
        )}

        {trailingIcon && <div className="textfield-trailingicon">{trailingIcon}</div>}
      </div>

      {showError && <div className="textfield-error">{errorMsg}</div>}
    </div>
  );
};

export default TextField;
