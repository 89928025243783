import * as React from 'react';
import { PropsWithChildren, FC } from 'react';
import './styles/Collapse.scss';

export const Collapse: FC<PropsWithChildren<{ isExpanded: boolean }>> = ({
  isExpanded,
  children,
}) => {
  return (
    <div className={`collapse ${isExpanded ? 'is--open' : ''}`}>
      <div className="collapse-inner">{children}</div>
    </div>
  );
};
